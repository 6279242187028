
import Vue from "vue";

import { Property, Breadcrumb } from "@/interfaces";

import http from "@/http";

export interface DataType {
  property?: Property;
}

export default Vue.extend({
  data(): DataType {
    return {
      property: undefined
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.property == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          to: "/"
        },
        {
          text: "建物",
          to: "/buildings",
          exact: true
        },
        {
          text: this.property.agreement.building.name,
          to: `/building/${this.property.agreement.building_id}/property`
        },
        {
          text: "編集履歴"
        }
      ];
    }
  },
  watch: {
    async $route() {
      await this.fetch();
    }
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      this.property = undefined;

      const { id } = this.$route.params;

      const url = `properties/${id}`;

      const { data } = await http.get<Property>(url);

      this.property = data;
    }
  }
});
